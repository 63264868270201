import { HttpErrorResponse } from '@angular/common/http';
import { UserColumn } from '@gridscale/ingrid/components/data-table';
import { createReducer, on } from '@ngrx/store';

import * as UserStoreActions from './userstore.actions';

export interface UserStoreData {
  mainNavigation?: string[];
  user_flags?: any;
  listColumns?: { [listKey: string]: UserColumn[] };
}

export interface State {
  loading: boolean;
  loaded: boolean;
  patching: boolean;
  error?: Error | HttpErrorResponse | undefined;
  data?: UserStoreData;
}

export const initialState: State = {
  loading: false,
  loaded: false,
  patching: false,
  data: undefined
};

export const featureKey = 'userstore';

export const reducer = createReducer(
  initialState,

  on(UserStoreActions.loadUserStore, (state, action) => ({
    ...state,
    loading: true,
    loaded: false
  })),

  on(UserStoreActions.loadUserStoreSuccess, (state, action) => ({
    ...state,
    loading: false,
    loaded: true,
    error: undefined,
    data: action.data
  })),

  on(UserStoreActions.loadUserStoreFailure, (state, action) => ({
    ...state,
    error: action.error,
    loading: false,
    loaded: true
  })),

  on(UserStoreActions.saveUserStore, (state, action) => ({
    ...state,
    patching: true
  })),

  on(UserStoreActions.saveUserStoreSuccess, (state, action) => ({
    ...state,
    patching: false,
    error: undefined,
    data: action.data
  })),

  on(UserStoreActions.saveUserStoreFailure, (state, action) => ({
    ...state,
    error: action.error,
    patching: false
  }))
);
