import { User } from './../store/user/user.reducer';
import { Contract } from './../store/contract/contract.reducer';
import { UserRoleService } from './userrole.service';
import { isCurrentContractLoaded, getCurrentContract } from './../store/contract/contract.selectors';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { getCurrentUser, isCurrentUserLoaded } from '../store/user/user.selectors';
import * as _ from 'lodash';
import { ConfigService } from '@gridscale/ingrid/helper/services/config.service';
import { filter, first, map, take, distinctUntilChanged, withLatestFrom } from 'rxjs/operators';
import { Button, OverlayAlertService } from '@gridscale/ingrid/components/overlay-alert';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { AddPaymentComponent } from './../components/add-payment/add-payment.component';
import { getPaymentMode } from '../store/config/config.selectors';
import { size } from 'lodash';

import * as BillingSelectors from './../store/billing/billing.selectors';
import * as BillingActions from './../store/billing/billing.actions';
import { CmContract } from './../models/cm-contract.model';
import { PaymentMethod } from './../store/billing/billing.reducer';

export enum STEP {
  'COMPLETE_USER_INFORMATION' = 'COMPLETE_USER_INFORMATION',
  'VALIDATE_EMAIL' = 'VALIDATE_EMAIL',
  'COMPLETE_PAYMENT' = 'COMPLETE_PAYMENT',
  'AWAITING_ACTIVATION' = 'AWAITING_ACTIVATION',
  'WAITING_FOR_SEPA' = 'WAITING_FOR_SEPA',
  'FRAUD_CHECK' = 'FRAUD_CHECK',
  'READY' = 'READY'
}

@Injectable({
  providedIn: 'root'
})
export class UserStateService {
  private readonly modes: { [key: number]: { step: STEP; done: boolean; count: boolean }[] } = {
    // partner = gs => normal flow
    1: [
      { step: STEP.COMPLETE_USER_INFORMATION, done: false, count: true },
      { step: STEP.VALIDATE_EMAIL, done: false, count: true },
      { step: STEP.FRAUD_CHECK, done: true, count: false },
      { step: STEP.COMPLETE_PAYMENT, done: false, count: false },
      // done is set to true, for cases where sepa is not needed
      { step: STEP.WAITING_FOR_SEPA, done: true, count: false },
      { step: STEP.READY, done: false, count: false }
    ],
    // manual payment control, when partner != gs && signup
    2: [
      {
        step: STEP.COMPLETE_USER_INFORMATION,
        done: false,
        count: true
      },
      { step: STEP.VALIDATE_EMAIL, done: false, count: true },
      { step: STEP.AWAITING_ACTIVATION, done: true, count: true },
      { step: STEP.READY, done: false, count: false }
    ],
    // !signup && partner != gs
    3: [
      { step: STEP.COMPLETE_USER_INFORMATION, done: false, count: true },
      { step: STEP.VALIDATE_EMAIL, done: false, count: true },
      { step: STEP.READY, done: false, count: false }
    ]
  };
  constructor(
    private readonly store: Store,
    private readonly configService: ConfigService,
    private readonly userRoleService: UserRoleService,
    private readonly translate: TranslateService,
    private readonly overlayAlert: OverlayAlertService,
    private readonly router: Router
  ) {
    store
      .select(getCurrentUser)
      .pipe(filter(currentUser => currentUser !== undefined && currentUser !== null))
      .subscribe(() => {
        store
          .select(BillingSelectors.isActivatedPaymentMethodsLoaded)
          .pipe(withLatestFrom(store.select(BillingSelectors.isActivatedPaymentMethodsLoading)), distinctUntilChanged(), first())
          .subscribe(([loaded, loading]) => {
            if (!loaded && !loading) {
              store.dispatch(BillingActions.loadActivatedPaymentMethods());
            }
          });

        store
          .select(BillingSelectors.isContractLoaded)
          .pipe(withLatestFrom(store.select(BillingSelectors.isContractLoading)), distinctUntilChanged(), first())
          .subscribe(([loaded, loading]) => {
            if (!loaded && !loading) {
              store.dispatch(BillingActions.loadContract());
            }
          });
      });

    /**
    this.userState$.subscribe(state => console.log({ userState: state }));
    this.getNextStep$().subscribe(nextStep => console.log({ nextStep }));
    this.mode$.subscribe(mode => console.log({ mode }));
    */

  }

  hasDoneStep$(step: STEP): Observable<boolean> {
    return this.userState$.pipe(map(userState => userState.findIndex(elem => elem.step === step && elem.done === true) !== -1));
  }

  get userCanUsePanel$(): Observable<boolean> {
    return combineLatest([this.hasDoneStep$(STEP.READY), this.getNextStep$()]).pipe(
      map(
        ([done, nextStep]) => done || nextStep === STEP.COMPLETE_PAYMENT || nextStep === STEP.READY || nextStep === STEP.WAITING_FOR_SEPA || nextStep === STEP.AWAITING_ACTIVATION
      )
    );
  }

  get userHasToCompleteInfo$(): Observable<boolean> {
    return combineLatest([this.hasDoneStep$(STEP.READY), this.getNextStep$()]).pipe(map(([done, nextStep]) => done || nextStep === STEP.COMPLETE_USER_INFORMATION));
  }

  get userHasToValidateEmail$(): Observable<boolean> {
    return combineLatest([this.hasDoneStep$(STEP.READY), this.getNextStep$()]).pipe(map(([done, nextStep]) => done || nextStep === STEP.VALIDATE_EMAIL));
  }

  get userCannotUseRessources$(): Observable<boolean> {
    return combineLatest([this.hasDoneStep$(STEP.READY), this.getNextStep$()]).pipe(
      map(([done, nextStep]) => done || nextStep === STEP.COMPLETE_PAYMENT || nextStep === STEP.AWAITING_ACTIVATION || nextStep === STEP.WAITING_FOR_SEPA)
    );
  }

  getNextStep$(): Observable<string> {
    return this.userState$.pipe(
      map(userState => {
        return userState.find(elem => elem.done === false)?.step ?? STEP.READY;
      })
    );
  }

  /**
   * checks if the user can allocate payed ressources, if not display a dialog pointing the user to add a payment method
   * resolves with true if user can allocate ressources, false if not
   */
  canAllocatePayedRessources$() {
    return this.userCannotUseRessources$.pipe(
      take(1),
      withLatestFrom(this.modeNumber$, this.getNextStep$(), this.store.select(isCurrentContractLoaded), this.store.select(getCurrentContract)),
      map(([hasToAddPayment, modeNumber, nextStep, isContractLoaded, contract]) => {
        if (!isContractLoaded || contract?.payment_enabled === true) {
          return true;
        }

        if (hasToAddPayment) {
          const buttons: Button[] = [
            {
              label: this.translate.instant('GENERAL.CONTINUE_LOOKAROUND'),
              mode: 'secondary',
              keyShortcut: 'Escape'
            }
          ];
          if (modeNumber !== 2) {
            if (nextStep === STEP.WAITING_FOR_SEPA) {
              buttons.push({
                label: this.translate.instant('REGISTER.ACCOUNT_CONFIRMED_DIALOG.VERIFY_SEPA'),
                mode: 'primary',
                callback: (button, alert) => {
                  alert.close();
                  this.router.navigate(['/Billing']);
                }
              });
            } else {
              buttons.push({
                label: this.translate.instant('PAYMENT.ADD_METHOD'),
                mode: 'primary',
                callback: (button, alert) => {
                  alert.close();
                  this.router.navigate(['/Billing', 'PaymentMethod']);
                }
              });
            }
          }

          this.overlayAlert.custom('', AddPaymentComponent, buttons, {}, undefined, undefined, '700px');

          return false;
        }
        return true;
      })
    );
  }

  get userState$(): Observable<{ step: STEP; done: boolean; count: boolean }[]> {
    return combineLatest([
      this.store.select(isCurrentContractLoaded),
      this.store.select(isCurrentUserLoaded),
      this.store.select(BillingSelectors.isActivatedPaymentMethodsLoaded),
      this.userRoleService.ready$,
      this.store.select(BillingSelectors.hasUnvalidatedSepa),
      this.store.select(getCurrentContract),
      this.store.select(getCurrentUser),
      this.store.select(BillingSelectors.getContract),
      this.store.select(BillingSelectors.getActivatedPaymentMethods),
      this.mode$
    ]).pipe(
      filter(
        ([contractLoaded, userLoaded, pmLoaded, userRoleServiceReady, hasUnvalidatedSepa, contract, user, cmContract, activatedPaymentMethods, mode]) =>
          contractLoaded === true &&
          userLoaded === true &&
          pmLoaded === true &&
          userRoleServiceReady === true &&
          contract !== undefined &&
          user !== undefined &&
          cmContract !== null &&
          activatedPaymentMethods !== null &&
          mode !== undefined &&
          hasUnvalidatedSepa !== undefined
      ),
      map(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        ([contractLoaded, userLoaded, pmLoaded, userRoleServiceReady, hasUnvalidatedSepa, contract, user, cmContract, activatedPaymentMethods, mode]: [
          boolean,
          boolean,
          boolean,
          boolean,
          boolean,
          Contract,
          User,
          CmContract,
          PaymentMethod[],
          { step: STEP; done: boolean; count: boolean }[]
        ]) => {
          // If manual activation required
          if (contract.payment_enabled === false) {
            const idx = mode.findIndex(elem => elem.step === STEP.AWAITING_ACTIVATION);
            idx !== -1 ? (mode[idx].done = false) : null;
          }

          // Non Owners need all User Data
          if (this.userRoleService.getRole() != 'owner' && !_.isEmpty(user.first_name) && !_.isEmpty(user.last_name) && !_.isEmpty(user.phone_number)) {
            const idx = mode.findIndex(elem => elem.step === STEP.COMPLETE_USER_INFORMATION);
            idx !== -1 ? (mode[idx].done = true) : null;
          }

          // Owner need also a contract name  !? default
          if (
            this.userRoleService.getRole() == 'owner' &&
            !_.isEmpty(user.first_name) &&
            !_.isEmpty(user.last_name) &&
            !_.isEmpty(user.phone_number) &&
            !_.isEmpty(contract.name) &&
            contract.name != 'default'
          ) {
            const idx = mode.findIndex(elem => elem.step === STEP.COMPLETE_USER_INFORMATION);
            idx !== -1 ? (mode[idx].done = true) : null;
          }

          // We do not show the User Completed dialog for Partner Users
          if (user.relations?.partners && size(user.relations.partners) > 0) {
            const idx = mode.findIndex(elem => elem.step === STEP.COMPLETE_USER_INFORMATION);
            idx !== -1 ? (mode[idx].done = true) : null;
          }

          if (user.validated) {
            const idx = mode.findIndex(elem => elem.step === STEP.VALIDATE_EMAIL);
            idx !== -1 ? (mode[idx].done = true) : null;
          }

          if (cmContract.payment_mode === 'force_disabled' && activatedPaymentMethods.length > 0) {
            const idx = mode.findIndex(elem => elem.step === STEP.FRAUD_CHECK);
            idx !== -1 ? (mode[idx].done = false) : null;
          }

          // Show hint if mode is default and no activated payment methods are found
          if (cmContract?.payment_mode === 'default_payment_method' && activatedPaymentMethods.length > 0) {
            const idx = mode.findIndex(elem => elem.step === STEP.COMPLETE_PAYMENT);
            idx !== -1 ? (mode[idx].done = true) : null;
          }

          if (hasUnvalidatedSepa) {
            const idx = mode.findIndex(elem => elem.step === STEP.COMPLETE_PAYMENT);
            idx !== -1 ? (mode[idx].done = true) : null;

            const idxSepa = mode.findIndex(m => m.step === STEP.WAITING_FOR_SEPA);
            idxSepa !== -1 ? (mode[idxSepa].done = false) : null;
          }

          // if state active is enforce, overwrite all missing steps for payment
          if (cmContract.payment_mode === 'force_enabled') {
            const idx1 = mode.findIndex(elem => elem.step === STEP.FRAUD_CHECK);
            idx1 !== -1 ? (mode[idx1].done = true) : null;

            const idx2 = mode.findIndex(elem => elem.step === STEP.COMPLETE_PAYMENT);
            idx2 !== -1 ? (mode[idx2].done = true) : null;

            const idxSepa = mode.findIndex(m => m.step === STEP.WAITING_FOR_SEPA);
            idxSepa !== -1 ? (mode[idxSepa].done = true) : null;
          }

          const undone = mode.filter(elem => elem.done === false);

          if (undone.length === 0) {
            const idx = mode.findIndex(m => m.step === STEP.READY);
            idx !== -1 ? (mode[idx].done = true) : null;
          }

          return mode;
        }
      )
    );
  }

  get mode$(): Observable<{ step: STEP; done: boolean }[]> {
    return combineLatest([this.store.select(isCurrentContractLoaded), this.store.select(getCurrentContract), this.store.select(getPaymentMode)]).pipe(
      filter(([contractLoaded, contract]) => contractLoaded === true && contract !== undefined),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      map(([contractLoaded, contract, payment_mode]) => {
        if (payment_mode == 'auto_activation') {
          return this.modes[3];
        }

        if (payment_mode == 'manual_activation') {
          return this.modes[2];
        }

        return this.modes[1];
      })
    );
  }

  get modeNumber$(): Observable<number> {
    return combineLatest([this.store.select(isCurrentContractLoaded), this.store.select(getCurrentContract), this.store.select(getPaymentMode)]).pipe(
      filter(([contractLoaded, contract]) => contractLoaded === true && contract !== undefined),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      map(([contractLoaded, contract, payment_mode]) => {
        if (payment_mode == 'auto_activation') {
          return 3;
        }

        if (payment_mode == 'manual_activation') {
          return 2;
        }

        return 1;
      })
    );
  }
}
