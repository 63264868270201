import { Injectable } from '@angular/core';
import { AsyncSubject, BehaviorSubject, Observable, Subject, combineLatest } from 'rxjs';
import { ActionsSubject, Store } from '@ngrx/store';
import * as UserStoreActions from './userstore.actions';
import * as UserStoreSelectors from './userstore.selectors';
import { map, withLatestFrom, take, filter, distinctUntilChanged, debounceTime, switchMap } from 'rxjs/operators';
import { UserStoreData } from './userstore.reducer';
import * as _ from 'lodash';
import { UserStateService } from './../../services/userstate.service';
import { ofType } from '@ngrx/effects';
import { initedUserStoreEffects } from './userstore.actions';
import { AuthService } from '../../../auth/auth.service';

interface KeySubscription<T> {
  realKey: string | string[];
  subject: Subject<T>;
  lastValue: T;
}

@Injectable({
  providedIn: 'root'
})
export class UserStoreService {
  readonly loaded$: AsyncSubject<boolean> = new AsyncSubject();

  private loaded = false;
  private subscribedKeys: { [key: string]: KeySubscription<unknown> } = {};
  private userData$ = this.store.select(UserStoreSelectors.getUserStore);
  public readonly userCanUsePanel$ = this.userStateService.userCanUsePanel$;
  private data?: UserStoreData;

  constructor(
    private store: Store,
    private userStateService: UserStateService,
    private readonly actions$: ActionsSubject,
    private readonly authService: AuthService
  ) {
    this.actions$.pipe(
      ofType(initedUserStoreEffects),
      withLatestFrom(this.authService.session$)
    ).subscribe(([action, session]) => {
      // in case the user store effects gets initied after this service
      if (session !== null) {
        this.store.dispatch(UserStoreActions.loadUserStore());
      }
    });

    this.store
      .select(UserStoreSelectors.isLoaded)
      .pipe(
        take(1),
        withLatestFrom(this.store.select(UserStoreSelectors.isLoading), this.authService.session$),
        map(([loaded, loading, session]) => {
          if (!!session && !loaded && !loading) {
            this.store.dispatch(UserStoreActions.loadUserStore());
          }
        })
      ).subscribe();

    this.authService.session$.pipe(
      distinctUntilChanged(),
      filter(session => !!session),
      debounceTime(10),
      withLatestFrom(this.store.select(UserStoreSelectors.isLoaded), this.store.select(UserStoreSelectors.isLoading)),
    ).subscribe(([session, loaded, loading]) => {
      // in case session changed
      if (!!session && !loaded && !loading) {
        this.store.dispatch(UserStoreActions.loadUserStore());
      }
    })




    this.userData$.pipe(filter(data => data !== undefined)).subscribe(data => {
      this.data = data!;

      _.forEach(this.subscribedKeys, subscription => {
        const now = this.get(subscription.realKey, subscription.lastValue);

        if (!_.isEqual(now, subscription.lastValue)) {
          subscription.lastValue = now;
          subscription.subject.next(now);
        }
      });

      if (this.loaded === false) {
        this.loaded = true;
        this.loaded$.next(true);
        this.loaded$.complete();
      }
    });
  }

  /**
   * get value from userStore
   */
  get<T>(key: string | string[], defaultValue?: T): T {
    const val = _.get(this.data, key, defaultValue);
    return _.cloneDeep(val);
  }

  /**
   * subscribe to a value and get notified everytime it changes
   * @param key
   * @param defaultValue
   */
  subscribe<T>(key: string | string[], defaultValue?: T): Subject<T> {
    let keyStr: string;
    if (_.isArray(key)) {
      keyStr = (key as string[]).join('/');
    } else {
      keyStr = key as string;
    }

    if (this.subscribedKeys[keyStr] === undefined) {
      this.subscribedKeys[keyStr] = {
        realKey: key,
        subject: new BehaviorSubject<T>(this.get(key, defaultValue)),
        lastValue: this.get(key, defaultValue)
      };
    }

    return (this.subscribedKeys[keyStr] as KeySubscription<T>).subject;
  }

  /**
   * Checks if userstore has value
   */
  has(key: string | string[]): boolean {
    return undefined !== _.get(this.data, key, undefined);
  }

  /**
   * Sets value in userstore, and triggers save action when data is loaded and no other save action is ongoing
   */
  set(key: string | string[], value: unknown): void {
    this.loaded$.pipe(take(1)).subscribe(() => {
      const data = _.cloneDeep(this.data!);
      _.set(data, key, _.cloneDeep(value));

      this.store.dispatch(UserStoreActions.saveUserStore({ data }));
    })
  }

  /**
   * clears the userstore. Shoud normally NOT BE USED - only in debug window
   * @deprecated
   */
  clear(): Observable<boolean | void> {
    this.store.dispatch(UserStoreActions.saveUserStore({ data: {} }));
    return this.store.select(UserStoreSelectors.isLoading).pipe(
      filter(loading => loading === false),
      take(1)
    );
  }
}
