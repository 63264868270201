<div class="p-grid">
  <div class="p-col-3 coins">
  </div>

  <div class="p-col-9 add-payment-message" *ngIf="(userModeNumber$ | async) === 2">
    <strong style="font-size: 1.25rem">{{ 'PAYMENT.MISSING_ACTIVATION_POPUP.HEADLINE' | translate }}</strong>
  </div>
  <div class="p-col-9 add-payment-message" *ngIf="(userModeNumber$ | async) !== 2">
    <strong style="font-size: 1.25rem">{{ 'PAYMENT.MISSING_METHOD_POPUP.HEADLINE' | translate }}</strong>
  </div>
</div>

<div class="p-grid">
  <div class="p-col-3 coins">
    <i *ngIf="(userModeNumber$ | async) !== 2"class="fal fa-coins"></i>
    <i *ngIf="(userModeNumber$ | async) === 2"class="fal fa-hourglass"></i>
  </div>

  <div class="p-col-9 add-payment-message" *ngIf="(userModeNumber$ | async) === 2">
    <p>{{ 'PAYMENT.MISSING_ACTIVATION_POPUP.LINE1' | translate }}</p>
    <p>{{ 'PAYMENT.MISSING_ACTIVATION_POPUP.LINE2' | translate }}</p>
    <p>{{ 'PAYMENT.MISSING_ACTIVATION_POPUP.LINE3' | translate }}</p>
  </div>
  <div class="p-col-9 add-payment-message" *ngIf="(userModeNumber$ | async) !== 2">
    <p>{{ 'PAYMENT.MISSING_METHOD_POPUP.LINE1' | translate }}</p>
    <p>{{ 'PAYMENT.MISSING_METHOD_POPUP.LINE2' | translate }}</p>
    <p>{{ 'PAYMENT.MISSING_METHOD_POPUP.LINE3' | translate }}</p>
  </div>
</div>
