import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromUserStore from './userstore.reducer';
import * as _ from 'lodash';

export const selectUserStoreState = createFeatureSelector<fromUserStore.State>(fromUserStore.featureKey);

/**
 * get current project
 */
export const getUserStore = createSelector(selectUserStoreState, (state: fromUserStore.State) => _.get(state, 'data'));

export const getUserMainNavigation = createSelector(selectUserStoreState, (state: fromUserStore.State) => _.get(state, ['data', 'mainNavigation']));
/**
 * get error
 */
export const getError = createSelector(selectUserStoreState, (state: fromUserStore.State) => _.get(state, 'error'));

/**
 * return if store is loading
 */
export const isLoading = createSelector(selectUserStoreState, (state: fromUserStore.State) => _.get(state, 'loading', false));

/**
 * return if store is loaded
 */
export const isLoaded = createSelector(selectUserStoreState, (state: fromUserStore.State) => _.get(state, 'loaded', false));

/**
 * return if store is patching
 */
export const isPatching = createSelector(selectUserStoreState, (state: fromUserStore.State) => _.get(state, 'patching', false));
