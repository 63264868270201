import { Component } from '@angular/core';
import { UserStateService } from '../../services/userstate.service';
import { TranslateModule } from '@ngx-translate/core';
import { NgIf, AsyncPipe } from '@angular/common';

@Component({
    selector: 'app-add-payment',
    templateUrl: './add-payment.component.html',
    styleUrls: ['./add-payment.component.scss'],
    standalone: true,
    imports: [NgIf, AsyncPipe, TranslateModule]
})
export class AddPaymentComponent {

  userModeNumber$ = this.userStateService.modeNumber$;

  constructor(private readonly userStateService: UserStateService) { }


}
